import React from 'react';
import Transliteration from '../transliteration/Transliteration';

function TryNow() {
  return (
    <Transliteration></Transliteration>
  );
}

export default TryNow;
